import "core-js/modules/es.array.includes.js";
import { reactive, toRefs, onBeforeMount, onMounted } from 'vue';
import usebase from '@/hooks/usebase';
import { wxOpenOauth } from '@/api/user';
import { useRouter } from 'vue-router';
import logo from '@/components/logo';
export default {
  name: '',
  props: [],
  components: {
    logo
  },

  setup() {
    const state = reactive({});
    const refState = toRefs(state);
    const router = useRouter();
    const {
      getUrlParam,
      isMessage
    } = usebase();

    const handlerwxOpenOauth = code => {
      wxOpenOauth({
        code: code
      }).then(res => {
        console.log(res);
        let {
          status,
          data
        } = res;

        if (status == 200) {
          if (data.token) {
            toPath(data.token);
          } else {
            console.log(data);
            localStorage.setItem('wxuser', JSON.stringify(data));
            router.push({
              path: '/register',
              query: {
                type: 'wx'
              }
            });
          }
        } else {
          isMessage('error', '微信授权异常');
        }
      });
    }; // 登录成功路由处理


    const toPath = token => {
      let redirect = localStorage.getItem('redirect');
      localStorage.clear();

      if (!redirect) {
        window.location.href = 'http://www.ysbuy.com/';
      } else {
        if (redirect.includes('?')) {
          window.location.href = `${redirect}&code=${token}`;
        } else {
          window.location.href = `${redirect}?code=${token}`;
        }
      }
    };

    onBeforeMount(() => {
      console.log('2.组件挂载页面之前执行----onBeforeMount');
    });
    onMounted(() => {
      console.log('3.-组件挂载到页面之后执行-------onMounted');
      console.log(getUrlParam('code'));

      if (getUrlParam('code')) {
        handlerwxOpenOauth(getUrlParam('code'));
      }
    });
    return { ...refState
    };
  }

};